// Step 1: Import React
import Layout from '../../components/layout/LayoutComponentEn'
import { getImage } from 'gatsby-plugin-image'

import { useStaticQuery, graphql, Link } from 'gatsby'
import React, { useLayoutEffect } from 'react'
import Caroucel from "../../components/Caroucel"
import { ScrollMoves } from "../../components/gsapFunctions"


// Step 2: Define your component
const IndexPage = () => {

  useLayoutEffect(() => {
    let moves = ScrollMoves();
    return () => {
      moves.forEach((move) => {
        move.scrollTrigger.kill();
      })
    }
  })

  const dataQuery = useStaticQuery(graphql`
      query {
        allFile(
          filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "servicios"}}
          sort: {fields: name}
        ) {
          nodes {
            childImageSharp {
                gatsbyImageData(width: 400)
            }
            name
          }
        }
      }
  
    `)
    const imagesData = dataQuery.allFile.nodes.slice(0,3).map((node) => {
      return {
          image: getImage(node),
          title: node.name
      }
  });
  return (
    <Layout pageTitle="Inicio" content='' isHome={true}>
      <>
        <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
            <div className='sm:mx-72 my-24'>
              <h3 className='font-bold text-2xl text-center move-up'>NEUROMED & CARDIOMED RICARDO AND COMPANY LIMITED<br/>(NECARMED CIA LTDA).</h3>
              <p className='text-xl text-justify mt-8 move-up'>We are a company oriented to the health sector since our foundation in 2010. Throughout these years we have dedicated ourselves to the sale, marketing, distribution, import and export of Supplies, Medical Equipment and highly specialized implants, with a clear vision of the social responsibility involved in this economic activity.</p>
              <div className='flex justify-center my-10 move-up'>
              <Link className='font-bold text-lg md:text-2xl text-white text-center  md:px-5 md:py-2 px-3 py-1 block rounded hover:bg-secondary-dark bg-secondary ' to='/sobre_nosotros/'>Know more about us</Link>    
              </div>
            </div>
          <Caroucel title="Our Main Services" dataQuery={imagesData}/>
          <div className='flex justify-center my-10'>
            <Link className='font-bold text-lg md:text-2xl text-white text-center  md:px-5 md:py-2 px-3 py-1 block rounded hover:bg-secondary-dark bg-secondary ' to='/servicios/'>See all our services</Link>
          </div>
        </div>
      </>
    </Layout>
  )
}
// Step 3: Export your component
export default IndexPage

export const Head = () => (
  <>
    <title>Inicio | NecarMed</title>
  </>
)